import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { GlobalService } from '../services/global/global.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { NavigationURLs } from '../utils/application-constants';

export const authGuard: CanActivateFn = async (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const localStorageService = inject(LocalStorageService);
  const globalService = inject(GlobalService);
  const router = inject(Router);

  if (
    state.url.includes('/login/callback') ||
    state.url === NavigationURLs.CHANGE_PASSWORD
  ) {
    return true;
  }

  try {
    if (localStorageService.isAuthenticated()) {
      const authorizationObject = await lastValueFrom(
        globalService.getRoleModuleMappingData()
      );
      if (authorizationObject && authorizationObject.isAuthorized) {
        if (authorizationObject.hasPasswordExpired) {
          router.navigate([NavigationURLs.CHANGE_PASSWORD]);
          return false;
        } else {
          return true;
        }
      } else {
        router.navigate([NavigationURLs.UNAUTHORIZED_PAGE]);
        return false;
      }
    } else {
      router.navigate([NavigationURLs.LOGIN]);
      return false;
    }
  } catch (error) {
    console.error('Error in authGuard:', error);
    router.navigate([NavigationURLs.UNAUTHORIZED_PAGE]);
    return false;
  }
};
