import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { moduleAccessGuard } from './guards/module-access.guard';
import { publicGuard } from './guards/public.guard';
import { SKPTModules } from './utils/application-constants';
import { LoginComponent } from './components/login/login.component';
import { routeAccessGuard } from './guards/route-access.guard';

export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: 'login',
        title: 'Login',
        loadComponent: () =>
            import('./components/login/login.component').then(
                (m) => m.LoginComponent
            ),
        canActivate: [publicGuard],
    },
    {
        path: 'login/callback',
        component: LoginComponent,
    },
    {
        path: 'logout',
        loadComponent: () =>
            import('./components/logout/logout.component').then(
                (m) => m.LogoutComponent
            ),
    },
    {
        path: 'home',
        title: 'Home',
        data: { breadcrumb: 'Home' },
        loadComponent: () =>
            import('./components/home/home.component').then((m) => m.HomeComponent),
        canActivate: [authGuard],
        children: [
            { path: '', redirectTo: 'redirect-home', pathMatch: 'full' },
            {
                path: 'redirect-home',
                loadComponent: () =>
                    import('./components/redirect-home/redirect-home.component').then(
                        (m) => m.RedirectHomeComponent
                    ),
            },
            {
                path: 'sample-submission',
                title: 'Sample Submission',
                data: { breadcrumb: 'Sample Submission' },
                loadComponent: () =>
                    import(
                        './components/sample-submission-form/sample-submission-form.component'
                    ).then((m) => m.SampleSubmissionFormComponent),
                children: [
                    {
                        path: 'step1',
                        data: { breadcrumb: 'Step 1' },
                        loadComponent: () =>
                            import(
                                './components/sample-submission-form/details/details.component'
                            ).then((m) => m.DetailsComponent),
                    },
                    {
                        path: 'step2',
                        data: { breadcrumb: 'Step 2' },
                        loadComponent: () =>
                            import(
                                './components/sample-submission-form/section-a-submission/section-a-submission.component'
                            ).then((m) => m.SectionASubmissionComponent),
                    },
                    {
                        path: 'step3',
                        data: { breadcrumb: 'Step 3' },
                        loadComponent: () =>
                            import(
                                './components/sample-submission-form/section-b-safety/section-b-safety.component'
                            ).then((m) => m.SectionBSafetyComponent),
                    },
                    {
                        path: 'step4',
                        data: { breadcrumb: 'Step 4' },
                        loadComponent: () =>
                            import(
                                './components/sample-submission-form/section-c-shipping/section-c-shipping.component'
                            ).then((m) => m.SectionCShippingComponent),
                    },
                    {
                        path: 'step5',
                        data: { breadcrumb: 'Step 5' },
                        loadComponent: () =>
                            import(
                                './components/sample-submission-form/section-d-sample-units/section-d-sample-units.component'
                            ).then((m) => m.SectionDSampleUnitsComponent),
                    },
                    {
                        path: 'step6',
                        data: { breadcrumb: 'Step 6' },
                        loadComponent: () =>
                            import(
                                './components/sample-submission-form/section-e-submitter-info/section-e-submitter-info.component'
                            ).then((m) => m.SectionESubmitterInfoComponent),
                    },
                    { path: '', redirectTo: 'step1', pathMatch: 'full' },
                ],
            },
            {
                path: 'user',
                title: 'Users',
                loadComponent: () =>
                    import('./components/user-list/user-list.component').then(
                        (m) => m.UserListComponent
                    ),
                canActivate: [moduleAccessGuard],
                data: { moduleName: SKPTModules.USER, breadcrumb: 'Users' },
            },
            {
                path: 'customer',
                loadComponent: () =>
                    import('./components/customer-list/customer-list.component').then(
                        (m) => m.CustomerListComponent
                    ),
                canActivate: [moduleAccessGuard],
                data: { moduleName: SKPTModules.CUSTOMER },
            },
            {
                path: 'clients',
                title:'Clients',
                loadComponent: () =>
                    import('./components/client-home/client-home.component').then(
                        (m) => m.ClientHomeComponent
                    ),
                canActivate: [moduleAccessGuard],
                data: { moduleName: SKPTModules.CLIENT },
                children: [
                    { path: '', redirectTo: 'client', pathMatch: 'full' },
                    {
                        path: 'client',
                        loadComponent: () =>
                            import('./components/client-list/client-list.component').then(
                                (m) => m.ClientListComponent
                            ),
                        canActivate: [moduleAccessGuard, routeAccessGuard],
                        data: { moduleName: SKPTModules.CLIENT },
                    },
                    {
                        path: 'internalproject',
                        title: 'internalproject',
                        loadComponent: () =>
                            import('./components/internal-project/internal-project.component').then(
                                (m) => m.InternalProjectComponent
                            ),
                    },

                    {
                        path: 'projects',
                        title:'Projects',
                        loadComponent: () =>
                            import('./components/project-home/project-home.component').then(
                                (m) => m.ProjectHomeComponent
                            ),
                        data: { moduleName: SKPTModules.PROJECT },
                        children: [
                            { path: '', redirectTo: 'project', pathMatch: 'full' },
                            {
                                path: 'project',
                                title: 'Projects',
                                loadComponent: () =>
                                    import(
                                        './components/project-list/project-list.component'
                                    ).then((m) => m.ProjectListComponent),
                            },
                            {
                                path: 'internalproject',
                                title: 'Internal Project',
                                loadComponent: () =>
                                    import('./components/internal-project/internal-project.component').then(
                                        (m) => m.InternalProjectComponent
                                    ),
                            },
                            {
                                path: 'forms',
                                title:'Sample Forms',
                                loadComponent: () =>
                                    import(
                                        './components/sample-forms/sample-form-list/sample-form-list.component'
                                    ).then((m) => m.SampleFormListComponent),
                                data: { moduleName: SKPTModules.CLIENT },
                            },
                            {
                                path: 'form-home',
                                loadComponent: () =>
                                    import(
                                        './components/sample-forms/sample-form-list/sample-submission-form/form-home/form-home.component'
                                    ).then((m) => m.FormHomeComponent),
                                data: { moduleName: SKPTModules.CLIENT },
                            },
                            {
                                path: 'document',
                                title: 'Documents',
                                loadComponent: () =>
                                    import(
                                        './components/document-list/document-list.component'
                                    ).then((m) => m.DocumentListComponent),
                            },
                            {
                                path: 'report',
                                title: 'Report',
                                loadComponent: () =>
                                    import(
                                        './components/chart/report-chart/report-chart.component'
                                    ).then((m) => m.ReportChartComponent),
                            },
                            {
                                path: 'testing-and-results',
                                title: 'Testing & Results',
                                loadComponent: () =>
                                    import(
                                        './components/testing-and-results/testing-and-results.component'
                                    ).then((m) => m.TestingAndResultsComponent),
                            },
                            {
                                path: 'project-user',
                                title: 'Project Team',
                                loadComponent: () =>
                                    import(
                                        './components/project-user/project-user.component'
                                    ).then((m) => m.ProjectUserComponent),
                            },
                        ],
                    },
                    {
                        path: 'reports',
                        title: 'Reports',
                        loadComponent: () =>
                            import('./components/report-list/report-list.component').then(
                                (m) => m.ReportListComponent
                            ),
                    },
                ],
            },
            {
                path: 'internalproject',
                title: 'internalproject',
                loadComponent: () =>
                    import('./components/internal-project/internal-project.component').then(
                        (m) => m.InternalProjectComponent
                    ),
            },
            {
                path: 'projects',
                title: 'projects',
                loadComponent: () =>
                    import('./components/project-home/project-home.component').then(
                        (m) => m.ProjectHomeComponent
                    ),
                data: { moduleName: SKPTModules.PROJECT },
                children: [
                    { path: '', redirectTo: 'project', pathMatch: 'full' },
                    {
                        path: 'project',
                        title: 'Projects',
                        canActivate: [routeAccessGuard],
                        loadComponent: () =>
                            import('./components/project-list/project-list.component').then(
                                (m) => m.ProjectListComponent
                            ),
                    },

                ],
            },
            {
                path: 'role-module-mapping',
                loadComponent: () =>
                    import(
                        './components/role-module-mapping/role-module-mapping.component'
                    ).then((m) => m.RoleModuleMappingComponent),
                canActivate: [moduleAccessGuard],
                data: { moduleName: SKPTModules.ROLE_MODULE_MAPPING },
            },
            {
                path: 'program',
                loadComponent: () =>
                    import('./components/programs/programs.component').then(
                        (m) => m.ProgramsComponent
                    ),
                canActivate: [moduleAccessGuard],
                data: { moduleName: SKPTModules.PROGRAM },
            },
            {
                path: 'user-details',
                loadComponent: () =>
                    import('./components/user-details/user-details.component').then(
                        (m) => m.UserDetailsComponent
                    ),
            },
            {
                path: 'user-profile',
                title:'User Profile',
                loadComponent: () =>
                    import('./components/user-profile/user-profile.component').then(
                        (m) => m.UserProfileComponent
                    ),
            },
            {
                path: 'change-password',
                title:'Change Password',
                loadComponent: () =>
                    import('./components/change-password/change-password.component').then(
                        (m) => m.ChangePasswordComponent
                    ),
            },
            // {
            //   path: 'nav-chat-panel',
            //   title: 'Chat Panel',
            //   loadComponent: () =>
            //     import('./components/nav-chat-panel/nav-chat-panel.component').then(
            //       (m) => m.NavChatPanelComponent
            //     ),
            // },
            {
                path: 'unauthorised',
                title: 'Unauthorised User',
                loadComponent: () =>
                    import(
                        './components/shared/unauthorised-error-page/unauthorised-error.component'
                    ).then((m) => m.UnauthorisedErrorComponent),
            },
            {
                path: 'notifications',
                title: 'Notifications',
                loadComponent: () =>
                    import('./components/notification-list/notification-list.component').then((m) => m.NotificationListComponent),
            },
            // {
            //   path: 'pagenotfound',
            //   loadComponent: () => import('./shared/page-not-found/page-not-found.component').then((m) => m.PageNotFoundComponent),
            // },
            { path: '**', redirectTo: 'pagenotfound' },
        ],
    },
    {
        path: 'reset-password',
        title:'Reset Password',
        loadComponent: () =>
            import('./components/reset-password/reset-password.component').then(
                (m) => m.ResetPasswordComponent
            ),
    },
    {
        path: 'forgot-password',
        title: 'Forgot Password',
        loadComponent: () =>
            import('./components/forgot-password/forgot-password.component').then(
                (m) => m.ForgotPasswordComponent
            ),
    },
    {
        path: 'unauthorised',
        title: 'Unauthorised User',
        loadComponent: () =>
            import(
                './components/shared/unauthorised-error-page/unauthorised-error.component'
            ).then((m) => m.UnauthorisedErrorComponent),
    },
    { path: '**', redirectTo: 'login' },
];
